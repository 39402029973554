export default {
  defaultAppConfig: {
    themes: {
      active: 'PufferPanel',
      available: ['PufferPanel']
    },
    branding: {
      name: 'PufferPanel'
    }
  }
}
