<!--
  - Copyright 2019 Padduck, LLC
  -  Licensed under the Apache License, Version 2.0 (the "License");
  -  you may not use this file except in compliance with the License.
  -  You may obtain a copy of the License at
  -          http://www.apache.org/licenses/LICENSE-2.0
  -  Unless required by applicable law or agreed to in writing, software
  -  distributed under the License is distributed on an "AS IS" BASIS,
  -  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  -  See the License for the specific language governing permissions and
  -  limitations under the License.
  -->

<template>
  <v-card>
    <v-card-title v-text="$t('users.Users')" />
    <v-card-text>
      <v-row>
        <v-col
          v-for="(user, i) in users"
          :key="i"
          cols="12"
          md="6"
        >
          <v-card
            class="mb-4"
            outlined
          >
            <v-card-title>
              <ui-input
                v-if="user.new"
                v-model="user.email"
                hide-details
                type="email"
                :label="$t('users.Email')"
                icon="mdi-email"
                @keyup.enter="updateUser(user)"
              />
              <span
                v-if="!user.new"
                v-text="user.username || user.email"
              />
              <v-btn
                v-if="!user.new"
                icon
                @click="toggleEdit(user.username)"
              >
                <v-icon v-text="editUsers.indexOf(user.username) > -1 ? 'mdi-close' : 'mdi-pencil'" />
              </v-btn>
            </v-card-title>
            <v-card-text v-if="user.new || (editUsers.indexOf(user.username) > -1)">
              <v-row>
                <v-col
                  v-for="option in options"
                  :key="option.value"
                  class="pt-1 pb-0"
                  cols="12"
                  md="6"
                >
                  <ui-switch
                    v-model="user[option.value]"
                    :label="option.text"
                    color="primary"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-btn
                    large
                    block
                    color="success"
                    @click="updateUser(user)"
                    v-text="user.new ? $t('common.Save') : $t('common.Update')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-btn
                    large
                    block
                    color="error"
                    @click="deleteUser(user)"
                    v-text="user.new ? $t('common.Cancel') : $t('common.Delete')"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            block
            @click="addUser()"
            v-text="$t('users.Add')"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    server: { type: Object, default: () => {} }
  },
  data () {
    return {
      users: [],
      editUsers: [],
      options: [
        { text: this.$t('scopes.ServersEdit'), value: 'editServerData' },
        { text: this.$t('scopes.ServersInstall'), value: 'installServer' },
        { text: this.$t('scopes.ServersConsole'), value: 'viewServerConsole' },
        { text: this.$t('scopes.ServersConsoleSend'), value: 'sendServerConsole' },
        { text: this.$t('scopes.ServersStop'), value: 'stopServer' },
        { text: this.$t('scopes.ServersStart'), value: 'startServer' },
        { text: this.$t('scopes.ServersStat'), value: 'viewServerStats' },
        { text: this.$t('scopes.ServersFiles'), value: 'sftpServer' },
        { text: this.$t('scopes.ServersFilesGet'), value: 'viewServerFiles' },
        { text: this.$t('scopes.ServersFilesPut'), value: 'putServerFiles' },
        { text: this.$t('scopes.ServersEditUsers'), value: 'editServerUsers' }
      ]
    }
  },
  mounted () {
    this.loadUsers()
  },
  methods: {
    async loadUsers () {
      this.users = await this.$api.getServerUsers(this.server.id)
    },
    addUser () {
      const newUser = {}
      newUser.new = true
      newUser.email = ''
      for (const option in this.options) {
        newUser[this.options[option].value] = false
      }
      this.users.push(newUser)
    },
    async updateUser (user) {
      if (user.new && (!user.email || user.email === '')) {
        this.$toast.error(this.$t('users.NoEmailGiven'))
        return
      }

      for (const key of Object.keys(user)) {
        user[key] = (user[key] === 'true') ? true : (user[key] === 'false') ? false : user[key]
      }

      await this.$api.updateServerUser(this.server.id, user)
      this.$toast.success(this.$t('common.Saved'))
      this.loadUsers()
    },
    toggleEdit (username) {
      if (this.editUsers.indexOf(username) > -1) {
        this.editUsers.splice(this.editUsers.indexOf(username), 1)
      } else {
        this.editUsers.push(username)
      }
    },
    async deleteUser (user) {
      if (user.new) {
        this.$delete(this.users, this.users.indexOf(user))
        return
      }

      await this.$api.deleteServerUser(this.server.id, user.email)
      this.loadUsers()
    }
  }
}
</script>
